<template>
	<div v-if="!mapLoaded" class="d-flex justify-center align-center" style="height: 100vh">
		<v-progress-circular indeterminate :color="primaryColor"></v-progress-circular>
	</div>
</template>

<script>
import { search, isSuperAdmin, getImagePath } from '@/api/common';
// eslint-disable-next-line no-unused-vars
//import { NOTIFICATIONS_LAYER, setNotificationsLayerConstants } from '@/lib/constants/layers';

export default {
	data() {
		return {
			primaryColor: this.$store.state.global.primaryColor
		};
	},
	computed: {
		mapLoaded() {
			return this.$store.state.map.mapLoaded;
		}
	},
	watch: {},
	created() {
		//console.log('=> created - home index');
		this.getAppData();
		this.storeIcons();
	},
	beforeMount() {
		//console.log('=> before mount - home index');
	},
	mounted() {
		//console.log('=> mounted - home index');
	},
	beforeUpdate() {
		//console.log('=> before update - home index');
	},
	updated() {
		//console.log('=> updated - home index');
	},

	methods: {
		async storeIcons() {
			let body = {
				model: 'vluppmicons',
				filter: {},
				rows: 99
			};
			let { data } = await search(body);
			//console.log('After get ALL icons: ', data.data);
			this.$store.commit('setIcons', data.data);
			return data;
		},

		async getAppData() {
			let body = {
				model: 'pmapplication',
				filter: null,
				rows: 1
			};

			let { data } = await search(body);
			this.$store.commit('setApplication', data.data[0]);
			this.$store.commit('setUrlBaseIcons', data.data[0].urlbase);
			//console.log('URL BASE: ' + this.$store.getters.getUrlBaseIcons);
			document.title = data.data[0].appname;

			var appLogoContainer = document.getElementsByClassName('puimenu-leftBar__logo-img')[0];
			//console.warn(appLogoContainer.hasChildNodes());
			if (!appLogoContainer.hasChildNodes()) {
				const appLinkWrapper = document.createElement('a');
				appLinkWrapper.href = data.data[0].urlbase;
				appLinkWrapper.classList.add('d-flex', 'justify-center', 'align-center');
				const appLogo = document.createElement('img');
				appLogo.setAttribute('src', getImagePath(data.data[0].urlbase, data.data[0].iconroute));
				appLinkWrapper.appendChild(appLogo);
				appLogoContainer.appendChild(appLinkWrapper);
				appLogoContainer.classList.add('d-flex', 'justify-center', 'align-center');
				appLogoContainer.setAttribute('title', data.data[0].appname);
				appLogo.style.width = '28px';
				appLogo.style.height = '28px';
			}

			var link = document.querySelector("link[rel~='icon']");
			if (!link) {
				link = document.createElement('link');
				link.rel = 'icon';
				document.head.appendChild(link);
			}
			link.href = data.data[0].urlbase + data.data[0].faviconroute;

			//console.log('APPLICATION DATA', this.$store.getters.getApplication, link);
			return data.data;
		},
		async getPropertiesByUser() {
			var session = this.$store.getters.getSession;
			//console.log('SESSION');
			//console.log(session);
			if (!this.userProperties) {
				//console.log('Hago de nuevo la petición');
				const filterUser = {
					groups: [],
					groupOp: 'and',
					rules: [
						{ field: 'usr', op: 'eq', data: session.usr },
						{ field: 'idiom', op: 'eq', data: session.language }
					]
				};

				const body = {
					model: 'pmusers',
					filter: filterUser,
					rows: 1
				};

				const { data } = await search(body);
				this.$store.commit('setUserProperties', data.data[0]);
			}

			session = this.userProperties;
			//console.log(session);

			this.hasAqiPoints();
			this.hasDevices();
		},
		async hasDevices() {
			var filterDevice = null;
			if (!isSuperAdmin(this.session.profiles[0])) {
				filterDevice = {
					groups: [],
					groupOp: 'and',
					rules: [
						{ field: 'pmorganizationid', op: 'eq', data: this.userProperties.organizationid },
						{ field: 'disabled', op: 'eq', data: 0 }
					]
				};
			}

			let body = {
				model: 'vlupdevicespatialmodel',
				filter: filterDevice,
				rows: 999
			};
			let { data } = await search(body);
			this.$store.commit('setDevices', data.data);
			this.$store.commit('setHasDevices', Object.keys(data.data).length > 0);
		},

		async hasAqiPoints() {
			var filterAqiPoint = null;
			if (!isSuperAdmin(this.session.profiles[0])) {
				filterAqiPoint = {
					groups: [],
					groupOp: 'and',
					rules: [
						{ field: 'pmorganizationid', op: 'eq', data: this.userProperties.organizationid },
						{ field: 'disabled', op: 'eq', data: 0 }
					]
				};
			}

			let body = {
				model: 'pmaqipoints',
				filter: filterAqiPoint,
				rows: 999
			};
			let { data } = await search(body);
			this.$store.commit('setAqiPoints', data.data);
			this.$store.commit('setHasAqiPoints', Object.keys(data.data).length > 0);
		}
	}
};
</script>
